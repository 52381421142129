import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { OrganizationRoomsResponse, UserRoomsResponse } from "../../../Types/Responses/RoomResponse"
import { User } from "../../../Types/User"
import RoomSlice, { setRoomsData } from "../SharedSlices/RoomSlice"
import DropdownItem from "../Primitive/DropdownItem"
import { CubeIcon, GreyVectorDown, GreyVectorRight, SearchIcon } from "./SvgIcons"
import { useNavigate, useParams } from "react-router-dom"
import { isDefined } from "../../../App/Helpers/Helpers"
import { RoleType } from "../../../App/enums"

function RoomDropdown(props: { type: RoleType.USER | RoleType.ORGANIZATION | string }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const translation = useSelector(selectTranslations)
    const roomState = useSelector((state: RootState) => state.roomSlice.roomsSlice.rooms)
    const ownedRoomState = useSelector((state: RootState) => state.roomSlice.roomsSlice.owned_rooms)
    const joinedRoomState = useSelector((state: RootState) => state.roomSlice.roomsSlice.joined_rooms)
    const [expanded, setExpanded] = useState(true)
    const [userRoomSlice, setUserRoomSlice] = useState(15)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)

    const userRoomGetRequest = (user: User) => {
        return HTTPService.get(`/user/${user.id}/room/`, headers)
    }

    const organizationRoomGetRequest = useCallback(() => {
        return HTTPService.get(`/organization/${id}/rooms/`, headers)
    }, [id])

    useEffect(() => {
        let isMounted = true

        switch (props.type) {
            case RoleType.USER:
                if (userDataState.id) {
                    userRoomGetRequest(userDataState)
                        .then((response: UserRoomsResponse) => {
                            if (isMounted && response.status === 200) {
                                const data = response.data.room
                                dispatch(setRoomsData({ rooms: data }))
                            }
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }

                break
            case RoleType.ORGANIZATION:
                if (isDefined(id)) {
                    organizationRoomGetRequest()
                        .then((response: OrganizationRoomsResponse) => {
                            if (isMounted && response.status === 200) {
                                const data = response.data
                                dispatch(setRoomsData({ joined_rooms: data.joined_rooms, owned_rooms: data.owned_rooms }))
                            }
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }
                break
            default:
                break
        }
        return () => {
            isMounted = false
        }
    }, [dispatch, id, organizationRoomGetRequest, props.type, userDataState, roomState?.length])

    const onLoadMoreRoomsClick = () => {
        switch(props.type) {
            case RoleType.USER:
                setUserRoomSlice(userRoomSlice+5)
                break
            case RoleType.ORGANIZATION:
                navigate(`/organization-rooms/${id!}`)
                window.scrollTo(0, 0)
                break
        }
    }
    
    const onRoomClick = (roomId: number, organizationId: number) => {
        switch (props.type) {
            case RoleType.USER:
                navigate(`/room/${roomId}`)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(`/room/${organizationId}/${roomId}`)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    const onSearchIconClick = () => {
        switch (props.type) {
            case RoleType.USER:
                navigate(`/rooms`)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(`/rooms/${id!}`)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    const roomCount =
        props.type === RoleType.USER ? roomState && roomState!.length : ownedRoomState && joinedRoomState && ownedRoomState!.length + joinedRoomState!.length
    return (
        <div className="room-dropdown">
            <div className="room-dropdown__header" onClick={() => setExpanded(!expanded)}>
                {expanded ? <GreyVectorDown /> : <GreyVectorRight />}
                <div className="room-dropdown__cube-icon">
                    <CubeIcon />
                </div>
                <p className="room-dropdown__title">{translation.myRooms}</p>
                <div onClick={onSearchIconClick}>
                    <SearchIcon color="dark-blue" />
                </div>
            </div>
            {expanded && roomCount! > 0 && (
                <div>
                    {(props.type === RoleType.USER ? roomState?.slice(0, userRoomSlice) : ownedRoomState)?.map(item => {
                        return (
                            <div className="room-dropdown__element" key={item.id} onClick={() => onRoomClick(item.id!, parseInt(id!))}>
                                <DropdownItem name={item.name} showNotification={item.unseen_posts! > 0} />
                            </div>
                        )
                    })
                    }

                    {props.type === RoleType.ORGANIZATION && (
                        <>
                            {joinedRoomState!.length > 0 && ownedRoomState!.length > 0 && <div className={props.type && "room-dropdown__element-container"} />}
                            {joinedRoomState?.slice(0, 3).map(item => {
                                return (
                                    <div className="room-dropdown__element" key={item.id} onClick={() => onRoomClick(item.id!, parseInt(id!))}>
                                        <DropdownItem name={item.name} showNotification={item.unseen_posts! > 0}/>
                                    </div>
                                )
                            })}
                        </>
                    )}
                </div>
            )}

            {props.type === RoleType.USER && (
                <>{expanded && roomCount! > userRoomSlice && (
                    <div onClick={onLoadMoreRoomsClick} className="room-dropdown__button">
                        {translation.loadMoreRooms}
                    </div>
                )}
                </>
            )}

            {props.type === RoleType.ORGANIZATION && (
                <>{expanded && roomCount! >= 5 && (
                    <div onClick={onLoadMoreRoomsClick} className="room-dropdown__button">
                        {translation.seeAllResults}
                    </div>
                )}
                </>
            )}

        </div>
    )
}
export default RoomDropdown
