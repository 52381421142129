import { format as fnsFormat } from "date-fns"
import { enUS, de } from "date-fns/locale"
import { Language, StorageKey } from "../enums"
import SessionService from "../SessionService"
import { Room } from "../../Types/Room"
import { RefObject, useEffect, useMemo, useRef, useState } from "react"
export interface dateLanguage {
    english: Locale
    deutsch: Locale
}

export const formatDate = (date: string | number | Date, format: string) => {
    let isoDate
    let userTime
    const locales = { [Language.english]: enUS, [Language.deutsch]: de } as dateLanguage
    const language = (SessionService.getItem(StorageKey.language) as string) || Language.deutsch

    const locale = locales[language as keyof dateLanguage]

    const userOffset = new Date().getTimezoneOffset() * 60 * 1000
    try {
        isoDate = new Date(date)
        userTime = new Date(isoDate.getTime() - userOffset)
    } catch (err) {
        return null
    }
    return date ? fnsFormat(userTime, format, { locale }) : null
}

export const isDefined = (value: string | null | undefined) => {
    return value !== null && value !== undefined && value !== ""
}

export const capitalizeFirstLetter = (text: string) => {
    return text ? text.charAt(0).toUpperCase() + text.slice(1) : ""
}

export const openLinkInNewTab = () => {
    document.addEventListener("click", function (event: Event) {
        let element = event.target as HTMLElement
        const tagName = "A"
        if (element.tagName === tagName && !element.hasAttribute("target")) {
            element.setAttribute("target", "_blank")
            element.setAttribute("rel", "noreferrer")
        }
    })
}

export let ConvertStringToHTML = (string: string) => {
    let parser = new DOMParser()
    let doc = parser.parseFromString(string, "text/html")
    return doc.body
}

export const matchWord = (content: string, word: string) => {
    var result = new RegExp("\\b" + word + "\\b")
    return content.search(result)
}

export const addNewLine = (text: string) => {
    const newText = text?.split("\n").map((line, index) => (
        <p key={index} className={`${(line.length === 0 || /\s/.test(line)) && "new-line"}`}>
            {line}
        </p>
    ))
    return newText
}

export const convertBytesToUnits = (bytes: number) => {
    let units = ["B", "KB", "MB", "GB", "TB", "PB"]
    let i = 0

    for (i; bytes > 1024; i++) {
        bytes /= 1024
    }

    return bytes.toFixed(1) + " " + units[i]
}

export const documentNameLength = (name: string, screenWidth: number, breakpoint: number) => {
    if (screenWidth > breakpoint) {
        return name!.length > 25 ? name!.substring(0, 25) + "..." : name
    }
    return name!.length > 15 ? name!.substring(0, 15) + "..." : name
}

export const replaceHTMLCharactersWithCorrespondingLetters = (content: string) => {
    return content
        .replace(/&auml;/g, "ä")
        .replace(/&Auml;/g, "Ä")
        .replace(/&ouml;/g, "ö")
        .replace(/&Ouml;/g, "Ö")
        .replace(/&uuml;/g, "ü")
        .replace(/&Uuml;/g, "Ü")
        .replace(/&szlig;/g, "ß")
        .replace(/&agrave;/g, "à")
        .replace(/&Agrave;/g, "À")
        .replace(/&aacute;/g, "á")
        .replace(/&Aacute;/g, "Á")
        .replace(/&acirc;/g, "â")
        .replace(/&Acirc;/g, "Â")
        .replace(/&atilde;/g, "ã")
        .replace(/&Atilde;/g, "Ã")
        .replace(/&ccedil;/g, "ç")
        .replace(/&Ccedil;/g, "Ç")
        .replace(/&egrave;/g, "è")
        .replace(/&Egrave;/g, "È")
        .replace(/&eacute;/g, "é")
        .replace(/&Eacute;/g, "É")
        .replace(/&ecirc;/g, "ê")
        .replace(/&Ecirc;/g, "Ê")
        .replace(/&euml;/g, "ë")
        .replace(/&Euml;/g, "Ë")
        .replace(/&iuml;/g, "ï")
        .replace(/&Iuml;/g, "Ï")
        .replace(/&ocirc;/g, "ô")
        .replace(/&Ocirc;/g, "Ô")
        .replace(/&yuml;/g, "ÿ")
        .replace(/&Yuml;/g, "Ÿ")
        .replace(/&aelig;/g, "æ")
        .replace(/&AElig;/g, "Æ")
        .replace(/&ntilde;/g, "ñ")
        .replace(/&Ntilde;/g, "Ñ")
        .replace(/&uacute;/g, "ú")
        .replace(/&Uacute;/g, "Ú")
        .replace(/&#156;/g, "œ")
        .replace(/&#140;/g, "Œ")
        .replace(/&iacute;/g, "í")
        .replace(/&Iacute;/g, "Í")
        .replace(/&micro;/g, "µ")
}

export const arrayOfLength = (rooms: Room[]) => {
    let length = rooms?.map(room => room.posts?.length)
    return length
}

export default function useOnScreen(ref: RefObject<HTMLElement>) {
    const observerRef = useRef<IntersectionObserver | null>(null);
    const [isOnScreen, setIsOnScreen] = useState(false);
    useEffect(() => {
        observerRef.current = new IntersectionObserver(([entry]) =>
            setIsOnScreen(entry.isIntersecting)
        );

    }, []);

    useEffect(() => {
        if (ref.current instanceof Element) {
            observerRef.current?.observe(ref.current!);
            return () => {
                observerRef.current?.disconnect();
            };
        }

    }, [ref]);

    return isOnScreen;
}