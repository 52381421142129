import React, { RefObject, useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import ModalGroup from "../../Shared/Components/ModalGroup"
import Header from "../../Shared/Header/Header"
import Footer from "../../Shared/Layout/Footer"
import RoomHeader from "../Components/RoomHeader"
import { useNavigate, useParams } from "react-router-dom"
import { setRoomData, setRoomManagerData } from "../../Shared/SharedSlices/RoomSlice"
import { RootState } from "../../../App/store"
import { getRoomRequest } from "../RoomServiceRequests/RoomServiceRequests"
import { JoinStatus, PostType, RoomType } from "../../../App/enums"
import { getRoomPostsRequest } from "../RoomPost/RoomPostRequests"
import { setPostStatus, setRoomPostsData } from "../RoomPost/RoomPostSlice"
import { capitalizeFirstLetter } from "../../../App/Helpers/Helpers"
import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { setIsMember, setPendingType } from "../RoomSlices/MemberInRoomStatusSlice"
import BackgroundWrapper from "../../Shared/Components/BackgroundWrapper"
import RoomTextArea from "../Components/RoomTextArea"
import RoomInformation from "./RoomInformation"
import RoomPost from "../RoomPost/RoomPost"
import { LoadingAnimation } from "../../Shared/Components/SvgIcons"
import { hasPostDropdown } from "../RoomPost/hasPostDropdown"

function RoomScreen() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { roomId } = useParams()
    const [pageNum, setPageNum] = useState(1)
    const [hasNextPage, setHasNextPage] = useState(false)
    const [loadingPost, setLoadingPost] = useState(false)
    const translation = useSelector(selectTranslations)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const organizationDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const isRoomManager = useSelector((state: RootState) => state.roomSlice.roomManagerSlice.room_manager)
    const posts = useSelector((state: RootState) => state.RoomPostsSlice.roomPostsSlice.posts)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const isMemberInRoom = useSelector((state: RootState) => state.MemberInRoomStatusSlice.MemberInRoomStatusSlice.isMember)
    const allow_requests = roomDataState?.room_permissions && roomDataState?.room_permissions[0]?.allow_requests!
    const invite_others = roomDataState?.room_permissions && roomDataState?.room_permissions[0]?.invite_others!
    const share_documents = roomDataState?.room_permissions && roomDataState?.room_permissions[0]?.share_documents!
    const canSeePosts = roomDataState.type === RoomType.Private ? isMemberInRoom === JoinStatus.MEMBER : true
    const scrollContainer = useRef() as RefObject<HTMLDivElement>
    const postStatus = useSelector((state: RootState) => state.RoomPostsSlice.postStatusSlice.postsStatus)
    const [isLoading,setIsLoading] = useState(true)

    useEffect(() => {
        dispatch(setRoomPostsData([]))
    },[])

    useEffect(() => {
        if(undefined != roomDataState.id && parseInt(roomId!) == roomDataState.id){
            setIsLoading(false)
        }
    },[roomId,roomDataState])

    useEffect(() => {
        let isMounted = true
        getRoomRequest(parseInt(roomId!))
            .then(response => {
                if (isMounted && response.status === 200) {
                    dispatch(setRoomData(response.data))
                }
            })
            .catch(error => {
                if (error.response.status === 404) {
                    navigate("/not-found")
                }
            })
    }, [
        dispatch,
        roomId,
        roomDataState.name,
        roomDataState.photo,
        roomDataState.type,
        allow_requests,
        invite_others,
        share_documents,
        roomDataState.description,
        navigate,
    ])

    const getUserStatus = useCallback(() => {
        return HTTPService.get(`/user-status-in-room/${roomId}/`, headers)
    }, [roomId])

    useEffect(() => {
        getUserStatus().then(response => {
            if (response.status === 200) {
                dispatch(setIsMember(response.data.status))
                dispatch(setPendingType({ type: response.data.type, notification_id: response.data.notification_id }))
            }
        })
    }, [dispatch, getUserStatus])

    useEffect(() => {
        dispatch(setRoomManagerData(roomDataState?.room_managers?.some(manager => manager.id === userDataState.id)!))
    }, [dispatch, roomDataState?.room_managers, roomId, userDataState.id])

    useEffect(() => {
        dispatch(setPostStatus({ postsStatus: true, singularPostStatus: false, postedInRoomStatus: false }))
    }, [dispatch])

    useEffect(() => {
        const controller = new AbortController()
        const { signal } = controller
        if (postStatus) {
            setPageNum(1)
            getRoomPostsRequest(parseInt(roomId!), 1)
                .then(response => {
                    if (response.status === 200) {
                        dispatch(setRoomPostsData(response.data.results))
                        setHasNextPage(Boolean(response.data.next))
                        setLoadingPost(false)
                        dispatch(setPostStatus({ postsStatus: false, singularPostStatus: false, postedInRoomStatus: false }))
                    }
                })
                .catch(error => {
                    if (signal.aborted) return
                    console.log(error)
                })

            return () => {
                setPageNum(1)
                controller.abort()
            }
        }
    }, [dispatch, postStatus, roomId])

    const onScroll = () => {
        if (scrollContainer.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollContainer.current

            if (Math.ceil(scrollTop + clientHeight + 1) >= scrollHeight && hasNextPage && !postStatus && !loadingPost) {
                setLoadingPost(true)
                const controller = new AbortController()
                const { signal } = controller
                getRoomPostsRequest(parseInt(roomId!), pageNum + 1)
                    .then(response => {
                        if (response.status === 200) {
                            dispatch(setRoomPostsData([...posts, ...response.data.results]))
                            setHasNextPage(Boolean(response.data.next))
                            setLoadingPost(false)
                            setPageNum(pageNum + 1)
                        }
                    })
                    .catch(error => {
                        setPageNum(1)
                        if (signal.aborted) return
                        console.log(error)
                    })
                return () => {
                    setPageNum(1)
                    controller.abort()
                }
            }
        }
    }

    return (
        <BackgroundWrapper>
            <Header roomHeader={true} />
            <div className="room-screen">
                {isLoading ? (
                    <LoadingAnimation></LoadingAnimation>
                ):
                (
                    <div className="room-layout">
                    <RoomInformation isMemberInRoom={isMemberInRoom} hasPrivelegedPermission={isRoomManager} />
                    <div className="room-layout__right-panel">
                        <div
                            className={`room-layout__right-panel-header ${
                                (isMemberInRoom === JoinStatus.NON_MEMBER || JoinStatus.PENDING) && "room-layout__right-panel-header--join-button"
                            }`}
                        >
                            <RoomHeader
                                roomType={capitalizeFirstLetter(roomDataState?.type! === RoomType.Private ? translation.private : translation.public)}
                            />
                        </div>
                        <div className="room-layout__right-panel-content" ref={scrollContainer} onScroll={onScroll}>
                            {roomDataState.type && !canSeePosts ? (
                                <div className="room-layout__no-access">{translation.cantSeeRoomContent}</div>
                            ) : posts!.length > 0 ? (
                                posts?.map((post, index) => {
                                    return (
                                        <RoomPost
                                            key={post.id}
                                            showCommentIcon={true}
                                            postDropdown={hasPostDropdown(post, role, organizationDataState.id!, userDataState.id, isRoomManager)}
                                            postDropdownStyle={
                                                index === posts.length - 1
                                                    ? "room-post__right-panel-content room-post__right-panel-content--no-height"
                                                    : "room-post__right-panel-content room-post__right-panel-content--height-5"
                                            }
                                            noCursor={post.content_object === null ? true : false}
                                            post={post}
                                            postType={"posts"}
                                            showFullPost={false}
                                        />
                                    )
                                })
                            ) : (
                                <div className="room-layout__no-post">{translation.nopostsYet}</div>
                            )}

                            {loadingPost && <LoadingAnimation type="post" />}
                        </div>
                        {roomDataState.is_active && (
                            <RoomTextArea
                            hasPrivelegedPermission={isRoomManager}
                            isMemberInRoom={isMemberInRoom}
                            share_documents={share_documents}
                            type={PostType.post}
                        />
                        )}
                    </div>
                </div>
                )}
                
            </div>
            <Footer roomfooter={true} />
            <ModalGroup />
        </BackgroundWrapper>
    )
}

export default RoomScreen
