import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import "../../../Styles/main.scss"
import { Room } from "../../../Types/Room"
import UserCard from "./UserCard"
import { SendIcon, SmileIcon, SmileyFace } from "./SvgIcons"
import { organizationProfileDefault, profileDefault, roomDefaultPhoto } from "../../../App/GlobaleVariables"
import { RootState } from "../../../App/store"
import { useNavigate, useParams } from "react-router-dom"
import { ConvertStringToHTML, capitalizeFirstLetter, formatDate } from "../../../App/Helpers/Helpers"
import { CallerComponent, CommentType, EditorModifier, PostType, RoleType, RoomType } from "../../../App/enums"
import { convertTagElement } from "./Editor/convertTagElement"
import MessageBody from "./MessageBody"
import { MentionedUsersType, RoomPosts } from "../../../Types/RoomPosts"
import Image from "../../Shared/Primitive/Image"
import { setModalData } from "../SharedSlices/ModalSlice"
import Reaction from "./Reactions"
import Comments from "./Comments"
import CharacterCounter from "./CharacterCounter"
import EditorTextArea from "./Editor/EditorTextArea"
import { Editor as TinyMCEEditor } from "tinymce"
import Picker from "emoji-picker-react"
import { roomCommentPostRequest } from "../../Room/RoomPost/RoomPostRequests"
import { hashtagRegex } from "../../../App/Regex"
import { setPostContentErrorState, setRoomPostsData } from "../../Room/RoomPost/RoomPostSlice"
import HTTPService from "../../../App/HTTPService"
import { headers } from "../../../App/Axios"
import FeedComments from "./FeedComments"
import { relative } from "path"



function HoverBox(props: { room: Room }) {
    const translation = useSelector(selectTranslations)

    return (
        <div className="hoverbox">
            <div className="hoverbox__row">
                <div className="hoverbox__left-panel">
                    <div className="room-header__profile-photo">
                        <Image
                            src={props.room.photo || roomDefaultPhoto}
                            alt="room"
                            className="image__profile image__profile--size-small image__profile--fit"
                        />
                    </div>
                </div>
                <div className="hoverbox__right-panel">
                    <p className="hoverbox__room-name">{props.room.name}</p>
                    <p className="hoverbox__room-description truncated">{props.room.description.replace(/<[^>]*>/g, '')}</p>
                </div>
            </div>
            <div className="hoverbox__bottom-panel">
                <div className="hoverbox__row hoverbox__row__center">
                    <div
                        className={`rooms-dropdown__room-type ${props.room.type === RoomType.Private
                            ? "rooms-dropdown__room-type--background-color-gold"
                            : "rooms-dropdown__room-type--background-color-blue "
                            } hoverbox__room-type`}
                    >
                        {props.room.type === RoomType.Private ? translation.private : translation.public}
                    </div>
                    <div className="hoverbox__stat">{props.room.total_members} {props.room.total_members! < 2 ? translation.member : translation.members}</div>

                    <div className="hoverbox__stat">{props.room.total_posts} {props.room.total_posts! < 2 ? translation.post : translation.posts}</div>
                </div>
            </div>

        </div>
    )
}

export default HoverBox

