export const english = {
    edusiiaTitle: "The online community for education",
    signin: "Sign in",
    signup: "Sign up",
    feedWelcomeText: "Great to see you again and welcome to the dashboard of your organization.",
    hello: "Hello",
    posts: "Posts",
    newsMembersText: "Say hello to the newest members",
    sponsoredText: "Sponsored",
    here: "here",
    edusiiaMatchingConnectViaDirectMessage: " We will connect you individually via direct messages with one other",
    edusiiaMatchingRevokeDecisionByEmail: "edusiia member you might not have met otherwise. You can revoke your decision at any time by sending an email to",
    edusiiaMatchingText: "Just give it a try!",
    edusiiaMatchingYes: "Yes, I would like to participate",
    edusiiaMatchingNo: "No, I do not want to participate",
    receiveNewsLetter: "I confirm that I would like to receive the edusiia newsletter in accordance with the privacy policy.",
    revokeNewsLetter: "I can revoke this consent without affecting my membership at any time and without giving reasons.*",
    agreePrivacyPolicy: "I confirm that I have read and agree to the",
    privacyPolicy: "Privacy Policy",
    and: "and",
    termsOfUse: "Terms of use",
    join: "Join",
    none: "None",
    immediate: "Immediate",
    daily: "Daily",
    weekly: "Weekly",
    byEmail: "By email",
    byWeb: "Platform-Notifications",
    commentUser: "New comments on your posts",
    commentOnSamePost: "Comments on posts you commented on",
    roomInvitationUser: "Invitations to join rooms",
    tagsUser: "New tags",
    roomInvitationReplyUser: "Reactions to your invitations to join rooms",
    roomJoinRequestUser: "Requests to join rooms that you are a room manager of",
    roomJoinRequestOrganization: "Requests to join your rooms",
    roomJoinRequestReplyUser: "Reactions to requests to join rooms",
    addAdminUser: "Invited as an admin",
    removeAdminUser: "Removed as an admin",
    eventCommentUser: "Comments on events that you had commented on before",
    eventCommentOrganization: "New comments on your events",
    newPostInRoomUser: "New post in rooms you are a member of",
    newPostInRoomOrganization: "New post in your rooms",
    adminReplyUser: "Reactions to your invitations to become an admin",
    newMembersOrganization: "New members in your rooms",
    save: "Save",
    fragen: "Fragen",
    antworten: "Antworten",
    konzeptionelles: "Konzeptionelles",
    fAQKonzeptionellesQuestionOne: "Was ist edusiia?",
    fAQKonzeptionellesAnswerOne:
        "edusiia ist die produktive Online-Community für Bildung. Wir helfen Bildungsorganisationen, ihre Bezugsgruppen langfristig untereinander und mit anderen zu vernetzen - für einen erfolgreichen Bildungswandel.",
    fAQKonzeptionellesQuestionTwo: "Was ist das Ziel dieser Plattform?",
    fAQKonzeptionellesAnswerTwo:
        "Bildung ist der Schlüssel zu positivem Wandel. Bildungsenthusiast:innen und -organisationen bekommen hier die Möglichkeit, sich langfristig interdisziplinär zu verbinden, um Herausforderungen gemeinsam zu lösen und schneller Wirkung zu erzielen. Da draußen gibt es wahnsinnig viele motivierte Akteur:innen und tolle Einzelinitiativen - es ist an der Zeit, sie einander bekannt zu machen und gemeinsam großflächig Wandel voranzutreiben.",
    fAQKonzeptionellesQuestionThree: "Wer darf bei edusiia mitmachen?",
    fAQKonzeptionellesAnswerThree1:
        "Alle, die aktiv und begeistert in der Bildung tätig sind, heißen wir herzlich bei edusiia willkommen - wir sprechen Organisationen und Menschen an, die bildungsenthusiastisch sind oder es werden wollen.",
    fAQKonzaptionellesAnswerThree2:
        "Was bedeutet bildungsenthusiastisch? Wir verbinden damit, dass man zuversichtlich, begeisterungsfähig, respektvoll, überzeugend, motivierend, ein Stück weit idealistisch, zukunftsorientiert, empathisch, leidenschaftlich, hilfsbereit und offen ist.",
    fAQKonzaptionellesAnswerThree3:
        " Vom Lehrer, Quereinsteiger und Elternvertreter an einer Grundschule, einer Gründerin eines EdTech-Startups, Schüler:innen, Logopäd:innen, Lehramtsstudierenden oder einer Pädagogik-Professorin an einer Universität, Eltern im Homeschooling, Angestellten in einer schulbildungsorientierten NGO, Stiftung oder in einem Unternehmen bis hin zu jemandem, der sich politisch mit dem Thema beschäftigt oder Werkzeuge für lebenslanges Lernen in ihrer Freizeit entwickelt.",
    fAQKonzeptionellesQuestionFour: "Gibt es ein Mindestalter, um sich bei edusiia anzumelden?",
    fAQKonzeptionellesAnswerFour1:
        "Bei edusiia können alle Bildungsenthusiast:innen ab 13 Jahren mitmachen. Alle, die zwischen 13 und 17 Jahren alt sind, übermitteln edusiia vor der Registrierung in der edusiia Community eine schriftliche Einverständniserklärung ihrer Erziehungsberechtigten. So möchten wir sicherstellen, dass unsere minderjährigen Mitglieder sich mit dem Wissen und der Unterstützung ihrer Eltern in unserer Community bewegen.",
    hier: "Hier",
    fAQKonzeptionellesAnswerFour2: "findet ihr weitere hilfreiche Informationen über Regeln und Meldemöglichkeiten bei edusiia. Mit der E-Mail-Adresse",
    fAQKonzeptionellesAnswerFour3: "geben wir minderjährigen Mitgliedern und Interessent:innen und ihren Eltern die Möglichkeit, uns gezielt zu kontaktieren.",
    fAQKonzeptionellesQuestionFive: "Was ist mit Bildungsenthusiast:innen gemeint?",
    fAQKonzeptionellesAnswerFive1: "Bildungsenthusiast:innen sind diejenigen, die aktiv und begeistert im Bildungsbereich tätig sind.",
    fAQKonzeptionellesAnswerFive2:
        "Bildungsenthusiast:innen sind zuversichtlich, begeisterungsfähig, respektvoll, überzeugend, motivierend, ein Stück weit idealistisch, zukunftsorientiert, empathisch, leidenschaftlich, hilfsbereit und offen.",
    fAQKonzeptionellesAnswerFive3:
        "Vom Lehrer, Quereinsteiger und Elternvertreter an einer Grundschule, einer Gründerin eines EdTech-Startups, Schüler:innen, Logopäd:innen, Lehramtsstudierenden oder einer Pädagogik-Professorin an einer Universität, Eltern im Homeschooling, Angestellten in einer schulbildungsorientierten NGO, Stiftung oder in einem Unternehmen bis hin zu jemandem, der sich politisch mit dem Thema beschäftigt oder Werkzeuge für lebenslanges Lernen in ihrer Freizeit entwickelt.",
    fAQKonzeptionellesQuestionSix: "Was versteht edusiia unter “besserer Bildung”?",
    fAQKonzeptionellesAnswerSix1:
        "Wir halten diesen Begriff bewusst offen, da wir Bildungsenthusiast:innen aus sehr unterschiedlichen Richtungen ansprechen möchten und jede:r für ihren:seinen Bereich definieren darf, was dies bedeutet. Das verbindende Element spiegelt sich in",
    unserenWerten: "Unseren Werten",
    fAQKonzeptionellesAnswerSix2: ", denen sich edusiia und die Bildungsentusiast:innen auf der Plattform verpflichtet fühlen.",
    fAQKonzeptionellesQuestionSeven: "Wer steckt hinter edusiia ?",
    fAQKonzeptionellesAnswerSeven:
        "Hinter edusiia steht ein interdisziplinäres Team von Bildungsenthusiast:innen, die Wandel in der Bildungswelt vorantreiben möchte. Mehr zu unseren Beweg- und Hintergründen findest du unter",
    uberUns: "Über uns",
    fAQKonzeptionellesQuestionEight: "Kostet die Mitgliedschaft bei edusiia Geld?",
    fAQKonzeptionellesAnswerEight: "Nein, die Mitgliedschaft bei edusiia ist kostenlos.",
    fAQKonzeptionellesQuestionNine: "Wie ist der Datenschutz bei edusiia geregelt?",
    fAQKonzeptionellesAnswerNine1:
        'Der Schutz deiner Daten ist für uns ein wichtiges Anliegen. Deshalb hosten wir zum Beispiel unsere Google-Schriften lokal, damit der Datenaustausch mit Google für dich entfällt. Außerdem nutzen wir das Analyse-Tool "Matomo". Die Software läuft dabei ausschließlich auf den Servern unserer Webseite. Eine Speicherung deiner personenbezogenen Daten findet nur dort statt, deine IP-Adresse wird auch für uns nur anonymisiert angezeigt',
    fAQKonzeptionellesAnswerNine2: "findest du unsere ausführliche Datenschutzerklärung.",
    fAQKonzeptionellesQuestionTen: "Was ist der Vorteil von edusiia gegenüber Facebook-Gruppen, Bildungs-Foren etc.?",
    fAQKonzeptionellesAnswerTen1:
        "edusiia ist gezielt auf Akteur:innen der Bildungslandschaft zugeschnitten. Unsere Community regt zu Austausch, Kollaboration und dem Lösen individueller Herausforderungen an.",
    fAQKonzeptionellesAnswerTen2:
        "Unsere Mitglieder erhalten auf sie zugeschnittene Vorschläge zu neuen Themen und für sie interessante Nutzer:innen. edusiia dient dem Ziel, den Bildungsbereich voranzutreiben und wird langfristig kuratierte Inhalte für die Community zur Verfügung stellen.",
    fAQEdusiiaMatchingQuestion: 'Was ist das "edusiia Matching"?',
    fAQEdusiiaMatchingAnswer1:
        "Aktuell nutzen wir für die Community eine fertige Software-Lösung, die nur sehr bedingt an die Bedürfnisse der Mitglieder angepasst werden kann. Bis die erste Version unserer eigenen Plattform fertig ist (wir arbeiten fleißig an der Umsetzung!), dient das edusiia Matching als Möglichkeit, unsere Mitglieder einander niedrigschwellig bekannt zu machen und ihnen einen Austausch zu ermöglichen, der sie sowohl individuell als auch innerhalb ihrer Projekte voranbringt. Wir übernehmen die initiale Vorstellung, alles Weitere überlassen wir den Vernetzten.",
    fAQEdusiiaMatchingAnswer2: "Was heißt das konkret?",
    fAQEdusiiaMatchingAnswer3:
        "Beim edusiia Matching stellen wir zum Monatsanfang teilnehmende Mitglieder einander via E-Mail vor, so dass Kontakte entstehen, die sonst vielleicht nicht entstanden wären. Die Vernetzung geschieht jeweils 1-zu-1 und auf der Grundlage der Angaben im Anmeldeformular und in der Community.",
    fAQEdusiiaMatchingAnswer4: "Bitte beachte, dass wir deine Email-Adresse beim edusiia Matching mit einem anderen Mitglied der Community teilen.",
    fAQEdusiiaMatchingAnswer5: "Schreibe eine Nachricht an",
    fAQEdusiiaMatchingAnswer6: ", wenn du deine Angabe zur Teilnahme am edusiia Matching ändern möchtest oder Fragen dazu hast.",
    anmeldung: "Anmeldung",
    fAQAnmeldungQuestionOne: "Warum muss ich mich anmelden, um mitzudiskutieren?",
    fAQAnmeldungAnswerOne1:
        "edusiia richtet sich gezielt an Bildungsenthusiast:innen, die sich für Bildung begeistern und motiviert und positiv an Bildungsthemen arbeiten. Durch die Anmeldung und damit verbundene Beantwortung zweier Fragen, möchten wir sicherstellen, dass alle Teilnehmenden sich auch als Bildungsenthusiast:innen verstehen und sich",
    fAQAnmeldungAnswerOne2: "verpflichtet fühlen.",
    fAQAnmeldungQuestionTwo: "Muss ich mich mit meinem Klarnamen anmelden?",
    fAQAnmeldungAnswerTwo: "Nein. Jedoch ist es empfehlenswert, damit dich Bekannte, Kolleg:innen und potentielle Kollaborateur:innen besser finden können.",
    fAQAnmeldungQuestionThree: "Muss ich meine:n Arbeitgeber:in angeben?",
    fAQAnmeldungAnswerThree:
        'Nein. Jedoch empfehlen wir dir die Angabe, damit dein Hintergrund und Kontext von allen Bildungsenthusiast:innen besser eingeordnet werden kann. Die entsprechende Information kannst du in deinem Profil unter "Titel" einfügen.',
    fAQAnmeldungQuestionFour: "Kann ich ein Profil für mein Unternehmen anlegen?",
    fAQAnmeldungAnswerFour1:
        "edusiia Mitglieder können in ihrer Profilbeschreibung ihre:n Arbeitgeber:in oder ihr eigenes Unternehmen nennen und vorstellen. Aktuell können sich ausschließlich Individuen bei edusiia anmelden, d. h. es gibt derzeit noch keine Möglichkeit, Profile für Unternehmen zu erstellen.",
    fAQAnmeldungAnswerFour2:
        "Zum einen möchten wir hiermit vermeiden, dass edusiia Profile vornehmlich für geschäftliche Zwecke genutzt werden. Zum anderen liegt uns gerade in der Anfangsphase viel daran, dass alle Mitglieder auf Augenhöhe miteinander in Kontakt treten, sich vernetzen und austauschen. Wir glauben fest daran, dass diese Verbindung zwischen Individuen essentiell für einen erfolgreichen Austausch und praktische Veränderung ist.",
    funktionen: "Funktionen",
    fAQFunktionenQuestionOne: "Wie funktioniert der Feed?",
    fAQFunktionenAnswerOne1:
        "Der Feed ist die Sammlung von den auf edusiia erstellten Beiträgen, der dir und allen anderen Mitgliedern den Inhalt auf eine sehr kontextbezogene Art und Weise liefert. Jeder Feed wird auf Basis individueller Faktoren wie den gefolgten Themen und Gruppen zusammengestellt. Folgst du einer Person, erhältst du eine Benachrichtigung, sobald sie einen Beitrag veröffentlicht.",
    fAQFunktionenAnswerOne2: "Zu dem edusiia Haupt-Feed haben alle Mitglieder Zugang, nachdem sie sich angemeldet haben.",
    fAQFunktionenQuestionTwo: "Warum sollte ich eigene Lösungen auf edusiia posten?",
    fAQFunktionenAnswerTwo:
        "edusiia eignet sich sehr gut, Bildungsideen mit einer interdisziplinären Gruppe an Menschen zu diskutieren, Feedback einzuholen und zu testen. Letztendlich sind alle Teilnehmenden daran interessiert, den Bildungsbereich voranzubringen.",
    fAQFunktionenQuestionThree: "Was passiert in den Gruppen?",
    fAQFunktionenAnswerThree1:
        "Gruppen sind eine Art Sub-Communities innerhalb der großen edusiia Community. Sie geben dir eine gute Möglichkeit, ein eingegrenztes Bildungsthema mit anderen Interessierten zu diskutieren und zu bearbeiten. Beiträge, die innerhalb einer Gruppenseite veröffentlicht werden, werden automatisch dieser Gruppe zugeordnet. Nur die Mitglieder dieser Gruppe können den Inhalt der Gruppe in ihrem Feed sehen.",
    fAQFunktionenAnswerThree2: "Es gibt drei Gruppenarten:",
    offentlicheGruppen: "Öffentliche Gruppen",
    fAQFunktionenAnswerThree3:
        'sind für alle edusiia Mitglieder zugänglich und jedes edusiia Mitglied kann den Inhalt vor dem Beitritt einsehen. Für den Beitritt zu einer öffentlichen Gruppe ist also keine Genehmigung erforderlich. Öffentliche Gruppen sind auch auf der Seite "Gruppen" sichtbar.',
    privateGruppen: "Private Gruppen",
    fAQFunktionenAnswerThree4:
        'hingegen sind nur für Gruppenmitglieder editierbar. Alle edusiia Mitglieder können die in der Gruppe veröffentlichten Inhalte vor dem Beitritt sehen. Der Beitritt zu einer privaten Gruppe erfordert die Genehmigung des Gruppen-Admins, des/der Gruppenmoderator:in oder des edusiia Admins. Private Gruppen sind genauso wie öffentliche Gruppen auf der Seite "Gruppen" sichtbar. ',
    geheimeGruppen: "Geheime Gruppen",
    fAQFunktionenAnswerThree5:
        'sind wie private Gruppen, mit dem Unterschied, dass sie nicht auf der "Gruppen"-Seite aufgeführt sind, so dass Nutzer:innen sie nicht entdecken können, es sei denn, sie werden in die Gruppe eingeladen. Die einzige Möglichkeit, einer geheimen Gruppe beizutreten, ist die Einladung durch den Gruppenadmin, die Gruppenmoderator:innen oder durch edusiia Admins. Letztere können den Inhalt der Gruppe sehen, auch wenn sie nicht zu ihren Mitgliedern gehören.',
    fAQFunktionenQuestionFour: "Was sind “Themen”?",
    fAQFunktionenAnswerFour:
        'Themen sind wie Schlagworte (Tags), die zum Organisieren von Inhalten verwendet werden. Themenseiten sind öffentlich und für jeden zugänglich. Einem Beitrag können mehrere Themen zugewiesen werden. Unter "Alle Themen" werden die Themen angezeigt, denen mindestens 3 Bildungsenthusiast:innen folgen.',
    fAQFunktionenQuestionFive: "Wer kann meine Beiträge sehen?",
    fAQFunktionenAnswerFive:
        "Wenn du deine Beiträge über den Home-Feed veröffentlichst, sind sie für alle Personen, die dir folgen oder nach dem von dir gewählten Thema suchen, sichtbar. Du kannst jedoch auch auswählen, dass deine Beiträge in einer bestimmten Gruppe veröffentlicht werden und sie damit nur für die Gruppenteilnehmer:innen sichtbar sind.",
    fAQFunktionenQuestionSix: "Wie kann ich weitere Bildungsenthusiast:innen zu edusiia einladen?",
    fAQFunktionenAnswerSix1:
        "Wir arbeiten momentan an einer Lösung, die dir ermöglicht, auf einfache und schnelle Weise andere Bildungsenthusiast:innen per E-Mail einzuladen. Verschicke bitte bis dahin den Link",
    fAQFunktionenAnswerSix2: "mit der Bitte, sich über den “Ich bin dabei”-Button anzumelden.",
    moderation: "Moderation",
    fAQModerationQuestionOne: "Wer moderiert die Beiträge?",
    fAQModerationAnswerOne1:
        "Jeder Beitrag wird sofort veröffentlicht und nicht vorher vom edusiia Team überprüft, da wir der Community das Vertrauen eines respektvollen Miteinanders entgegen bringen möchten. edusiia Community Supporter:innen sind kontinuierlich auf der Plattform aktiv und greifen bei Verstoß gegen",
    fAQModerationAnswerOne2:
        "ein. Alle teilnehmenden Bildungsenthusiast:innen auf der Plattform haben darüberhinaus die Möglichkeit, Beiträge jeglicher Art zu melden. edusiia Community Supporter:innen entscheiden dann von Fall zu Fall über die Konsequenzen der Meldung.",
    fAQModerationQuestionTwo: "Wie geht edusiia gegen Missbrauch, Hetze und Fake-Posts vor?",
    fAQModerationAnswerTwo1: "edusiia Community Supporter:innen achten darauf, dass",
    fAQModerationAnswerTwo2:
        " eingehalten werden und setzen sich, wenn notwendig, mit einzelnen Community-Mitgliedern in Kontakt, sollten sie gegen unsere Richtlinien verstoßen. Bildungsenthusiast:innen haben auch die Möglichkeit, andere Mitglieder und Beiträge bei Verstoß zu melden. Wir behalten uns vor, Mitglieder auszuschließen, sollten sie sich nicht an unsere Werte halten.",
    fAQHaveAnyQuestions: "Hast du die Antwort auf deine Frage hier nicht gefunden? Schreibe uns gerne eine Nachricht über unser",
    kontaktformular: "Kontaktformular",
    oderEineEMailAn: "oder eine E-Mail an",
    deleteAccount: "Delete account",
    doYouWantToDeleteAccount: "Do you want to delete your personal account?",
    deleteAccountAnytime: "You can delete your account at any time.",
    userDeleteAccountWithOrganization1: "You are the owner of at least one organizational profile. ",
    userDeleteAccountWithOrganization2:
        "To delete your personal account, you must first delete your organizational profile or transfer ownership to another member. To do this, go to your organizational profile and click on “Transfer Ownership”.",
    userDeleteAccountWithoutOrganization1:
        "(It's a pity that you don't want to be part of the community anymore. If there is something that would keep you as a member with us, contact us",
    userDeleteAccountWithoutOrganization2: "anytime.)",
    userDeleteAccountEduFanMember1:
        "(Too bad you don't want to be part of the community anymore! If there is something that would keep you as a member with us, contact us",
    userDeleteAccountEduFanMember2: "at any time. IMPORTANT: Please note that you have to cancel your eduFan membership separately.)",
    deleteAccountReason: "Why do you want to delete your profile?",
    acceptConsequences: "I have read and accept the consequences of deleting my profile",
    learnMoreAboutThat: "Learn more about that",
    delete: "Delete",
    howOthers: "How others",
    viewMyProfile: "view my profile",
    firstName: "First name",
    lastName: "Last name",
    email: "Email",
    securePassword: "Secure password",
    newSecurePassword: "New Secure password",
    repeatNewSecurePassword: "Repeat New Secure password",
    name: "Name",
    surname: "Surname",
    password: "Password",
    newPassword: "New password",
    repeatSecurePassword: "Repeat secure password",
    repeatPassword: "Repeat password",
    howDidYouLearnAboutEdusiia: "How did you learn about edusiia?",
    next: "Next",
    thisFieldIsRequired: "This field is required.",
    thisFieldContainsOnlyLetters: "This field must contain only letters and space.",
    enterValidEmail: "Please enter a valid email.",
    passwordLongerThan8: "Password should be longer than 8 characters and contain at least one letter and one number.",
    passwordsDontMatch: "Passwords don't match.",
    pleaseSelectAnAnswer: "Please select an answer.",
    pleaseSelect: "Please select...",
    organizationError: "An error occurred with the adding organization proccessed. You can still add your organization later.",
    addOrganizationProfile: "Add organizational profile ",
    addOrganizationIntroduction: "In addition to your personal profile, you can also create a profile for your organization here.",
    nameOfOrganization: "Name of the organization",
    isOrganizationRepresentative:
        "I verify that I am an authorized representative of this organization and have the right to act on its behalf in the creation and management of this profile.",
    tooltipOrganizationExample1: "For example: edusiia.com",
    myRoleinEducation: "My role in the education in three rows",
    whyAmIAtEdusiia: "Why am I at Edusiia",
    settings: "Settings",
    addOrganization: "Add Organization",
    logOut: "Log out",
    fAQ: "FAQ",
    myExperience: "My Experience",
    noExperience: "No experience shared yet",
    members: "members",
    public: "Public",
    private: "Private",
    myRooms: "My Rooms",
    noRooms: "You haven't joined a room yet",
    seeAllResults: "See all results",
    Admin: "Admin",
    edusiiaMatching: "edusiia Matching",
    OurRooms: "Our Rooms",
    CreateRoom: "Create Room",
    YouHaventAddedARoomYet: "You haven't added a room yet",
    OurEmployees: "Our Employees",
    YouHaventAddAnyEmployeeYet: "You haven't added any employee yet",
    noEmployeesToBeShow: "No employees to be show",
    whatWeDoForEducation: "What we do for education",
    emailInstructionSent: "Email instruction sent",
    pleaseFollowTheInstructions: "Please follow the instructions",
    weHaveSentToYourInbox: "we have sent to your inbox.",
    forgotPassword: "Forgot password",
    pleaseEnterYourRegisteredEmailAddress: "Please enter your registered email address. We’ll send instructions to help reset your password.",
    reset: "Reset",
    resetYourPassword: "Reset your password",
    minimumCharacters: "Minimum 8 characters.",
    mustContain: "Must contain",
    upperAndLowercaseNumbersAndSimbols: "upper and lowercase, numbers and symbols.",
    confirmNewPassword: "Confirm New password",
    changePassword: "Change Password",
    theOnlineCommunity: "The online community",
    forEducation: "for education",
    weConnectOrganizations: "We connect organizations",
    andPeopleWhoWantToMake: "and people who want to make",
    educationBetter: "education better.",
    aboutUs: "About us",
    ourValues: "Our values",
    eventCalendar: "Event calendar",
    supportEdusiia: "Support edusiia",
    youthProtection: "Youth protection",
    privacy: "Privacy",
    impressum: "Impressum",
    home: "Home",
    notifications: "Notifications",
    rooms: "Rooms",
    chat: "Chat",
    bookmarks: "Bookmarks",
    search_mobile: "Search...",
    search_desktop: "Search for people, organizations, rooms or topics",
    search_chat: "Search or start chat...",
    whoCanReachMe: "Who can reach me",
    otherMembersCanSendMePrivateMessages: "Other members can send me private messages",
    allowProfileInformationToBeSearched: "My profile information can be displayed in search results",
    doYouLikeYour: "Do you like your",
    experienceInEdusiia: "experience in edusiia?",
    considerDonatingToOurCause: "Consider donating to our cause.",
    donate: "Donate",
    pinboard: "Pinboard",
    markAllRead: "Mark all read",
    by: "by",
    joinEdusiiaToday: "Join Edusiia today",
    AllSettings: "All Settings",
    Profile: "Profile",
    notification: "Notification",
    Privacy: "Privacy",
    DeleteAccount: "Delete Account",
    headline: "Headline",
    subheadline: "Subheadline",
    mainText: "Main text",
    organizationName: "Organization Name",
    passwordRequirement1: "Password must be longer than 8 characters",
    passwordRequirement3: "than 8 characters and contain ",
    passwordRequirement2: " at least 1 number and 1 letter.",
    create: "Create",
    createOrganization: "Create Organization Profile",
    about: "About",
    website: "Company Website",
    optional: "(optional)",
    permissions: "Permissions",
    bookmark: "Bookmark",
    membersWhoJoinedEdusiia: "Members who recently joined Edusiia",
    changePhotoProfile: "Change photo profile",
    viewPhotoProfile: "View photo profile",
    maxImageSize: "(max. 4 MB)",
    recommendedProfilePhoto: "(500 x 500 pixel recommended)",
    recommendedCoverPhoto: "(2400 x 600 pixel recommended)",
    viewCoverPhoto: "View cover photo",
    changeCoverPhoto: "Change cover photo",
    noDescriptionYet: "You haven't added a description yet...",
    position: "Position",
    companyName: "Company Name",
    startDate: "Start Date",
    endDate: "End Date",
    deleteExperience: "Delete Experience",
    day: "Day",
    month: "Month",
    months: "Months",
    year: "Year",
    currentlyWorking: "I currently work at this role",
    present: "Present",
    crop: "Crop",
    location: "Location",
    locationPlaceholder: "City, Country",
    removeEmployee: "Remove employee?",
    removeEmployeeQuestion1: "Are you sure you want to remove",
    removeEmployeeQuestion2: "from your employees?",
    removeRoomMember: "Remove room member?",
    removeRoomMemberTitle: "Remove room member",
    removeRoomMemberQuestion1: "from your room?",
    remove: "Remove",
    addEmployees: "Add Employees",
    add: "Add",
    forProfitOrganization: "For-profit organization",
    nonProfitOrganization: "Non-profit organization",
    forProfit: "For-profit ",
    nonProfit: "Non-profit ",
    halloTeamOf: "Hallo team of",
    addTitle: "Add your title...",
    addDescription: "Add your description...",
    confirmPassword: "Confirm Password",
    myOrganizations: "My Organizations",
    individual: "Individual",
    organization: "Organization",
    goPremium: "Go Premium",
    account: "Account",
    deleteRoom: "Delete room",
    areYouSureToDeleteRoom: "Are you sure you want to delete this room?",
    roomInvitationRequests: "Room invitation requests",
    organizationCommentsNotification: "New comments on my posts in rooms or on pinboard",
    organizationTagsNotification: "Others tag me in a post/comment in a room",
    organizationRoomJoinNotification: "Reply to room joining requests",
    organizationAdminNotification: "Reply to admin invitation",
    organizationPostsNotification: "New posts in my rooms",
    organizationMembersNotification: "New member in my room",
    contributionAmount: "Contribution Amount ",
    perMonth: "Per Month",
    perYear: "Per Year",
    freeMonth: "(1 month for free)",
    otherAmount: "Other Amount",
    iWouldLikeToSuportEdusiia: "Yes, I would like to support edusiia GmbH with the above mentioned amount regularly. I can quit this support any time.",
    guidelinesForDonationsApply: "Guidelines for donations apply.",
    donateOnceByBankTransfer: "Donate once? By bank transfer.",
    myProfileInfoCanBeDisplayedInSearchResults: "My profile information can be displayed in search results on edusiia",
    myProfileInfoCanBeFoundInSearchEngines: "My profile can be found in search results of internet search engines",
    asTheOwnerYouCanDelete: "As the owner of this organizational profile, you can delete it at any time.",
    wantToDeleteOrganizationalAccount: "As the owner of this organizational profile, you can delete it at any time.",
    beforeProceedingWarning: "Before you do so, please note the following:",
    firstOrganizationDeleteAccountWarning:
        "You must cancel active paid memberships before deleting your profile (Settings → Membership). Payments already made will not be refunded if you delete your organization profile before your membership ends.",
    secondOrganizationDeleteAccountWarning:
        "In addition to profile information, rooms created by you or other admins of your organization will also be deleted from the network. Members will no longer have access to the content shared there.",
    thirdOrganizationDeleteAccountWarning:
        "Tip: You can transfer ownership of this profile and its associated permissions to another member. To do so, go to your profile and click “Transfer Ownership”.",
    permissionNeeded: "Permission needed!",
    noPermissionForPage: "You don’t have permission to access this page!",
    goToDashboard: "Go to dashboard",
    invitationLink: "Invitation link",
    copyLink: "Copy link",
    roomName: "Room Name",
    select: "Select",
    addPhoto: "Add Photo",
    uploadPhoto: "Upload Photo",
    roomManager: "Room Manager",
    seeMoreText: "Alles anzeigen",
    seeLessText: "Weniger anzeigen",
    allowMembersToChatAndShareDocuments: "Allow members to chat and share documents/pictures/videos",
    allowMembersToInviteOtherMembers: "Allow members to invite other members",
    allowMembersRequestToJoinTheRoom: "Allow members request to join the room",
    invite: "Invite",
    description: "Description",
    typeYourMessage: "Type your message",
    addAComment: "Add a comment…",
    roomCreator: "Room Creator",
    comments: "Comments",
    allPosts: "Go to more room posts",
    joinEvent: "Join Event",
    goPremiumToCreateMoreRooms: "Go Premium to Create More Rooms",
    deleteThisRoom: "Delete this room",
    deletingThisRoomWillLoseAllAssets: "(deleting this room you will lose all it's assets)",
    roomInformation: "Room Information",
    share: "Share",
    editRoom: "Edit Room",
    shareToRoom: "Share to Room",
    eventLink: "Event link",
    seeAllDetails: "See all details",
    showLess: "Show less",
    ifYouWantToChangeThisInfo: "If you want to change this info,",
    pleaseContactUsVia: "please contact us via",
    changeInfoMain: " mitgliedschaft@edusiia.com",
    alreadyHaveAnAccount: "Already have an account?",
    upgradeYourProfile: "Upgrade your profile.",
    unleashYourFullPotential: "Unleash your full potential.",
    hi: "Hi",
    team: "team",
    checkOutWhatOurUpgradedProfilesHaveToOfferYou: "Check out what our upgraded profiles have to offer you:",
    premiumSmall: "Premium Small",
    premiumMedium: "Premium Medium",
    premiumLarge: "Premium Large",
    premium: "Premium",
    book: "Book",
    custom: "Custom",
    contactTheEdisiiaTeam: "Contact the edusiia team through ",
    wellCreateAsuitablePackage: "and we'll create a suitable package deal for you.",
    edusiiaMail: "info@edusiia.com",
    checkOutWhatYouGetByUpgrading: "Check out what you get by upgrading your profile.",
    forFurtherInformation: "For further information, please read the",
    basic: "Basic",
    createYourCustomizablePage: "Create your own customizable profile page",
    showcaseYourIdeas: "Showcase your ideas and events on your personal pinboard",
    joinAndPartecipateInOtherRooms: "Join and participate in other rooms",
    createOnePrivate: "Create one private room (max. of 10 members)",
    displayYourPinboard: "Display your pinboard entries on other members dashboards",
    createUnlimitedRooms: "Create unlimited rooms (max. of 1.000 members across all rooms",
    deleteEvent: "Delete Event",
    nopostsYet: "no posts yet",
    edit: "Edit",
    member: "member",
    organizationEmail: "Organization Email",
    Email: "Email",
    newestOrganizations: "Newest Organizations",
    answers: "answers",
    answer: "answer",
    decline: "Decline",
    accept: "Accept",
    joinRoom: "Join Room",
    leaveRoom: "Leave Room",
    pending: "Pending",
    onlyMembersCanSeeOtherMembers: "Only members can see other members in this room",
    cantSeeRoomContent: "Only members can see the content of this room.",
    onlyMembersCanPostInRoom: "Join the room in order to share something.",
    invited: "Invited",
    noEndDateProvided: "Provide an end date or check the currently working box before saving this experience.",
    HowManyEmployeessYourOrganizationHave: "How many employees does your organization have?",
    oneToTen: "1-10 employees",
    elevenToOneHundred: "11-100 employees",
    moreThanOneHundred: ">100 employees",
    howMuchRevenuePerYear: "How much revenue does your organization make per year?",
    youHaveNoNotifications: "You have no notifications ",
    buy: "Buy",
    checkOutYour: "check out your",
    currentSubscriptionPlan: "current subscription plan",
    membership: "Mitgliedschaft",
    individuals: "Individuals",
    organizations: "Organizations",
    allResults: "All results",
    events: "Events",
    noResultThatContain: "No result that contain",
    wasFound: "was found.",
    nothingToShowYet: "Nothing to show yet.",
    room: "Room",
    yourUpdatingToTheFollowingPlan: "You are about to update your membership to the following:",
    upgradeConfirmation: "Contract adjustment",
    upgrade: "Order now",
    editPost: "Edit post",
    deletePost: "Delete post",
    editComment: "Edit comment",
    deleteComment: "Delete comment",
    areYouSureToDeletePost: "Are you sure you want to delete this post?",
    areYouSureToDeletePreview: "Are you sure you want to delete this link preview?",
    areYouSureToDeleteComment: "Are you sure you want to delete this comment?",
    cancel: "Cancel",
    Förderung: "Förderung",
    Förderung11:
        " Mit der Fördermitgliedschaft leiste ich bis zu meiner Kündigung an die edusiia GmbH eine regelmäßig wiederkehrende Zahlung in Höhe des von mir gewählten Betrags ohne Anspruch auf eine Gegenleistung (Schenkung).",
    Förderung12:
        " Da die edusiia GmbH nicht vom Finanzamt als gemeinnützig anerkannt ist, kann leider keine Zuwendungsbescheinigung (Spendenquittung) ausgestellt werden.",
    Zahlung: "Zahlung",
    Zahlung21: " Ich verpflichte mich für die Laufzeit meiner Fördermitgliedschaft zur Zahlung des von mir gewählten monatlichen Betrags.",
    Zahlung22:
        "Je nach der von mir gewählten Zahlungsart gelten ergänzend die Bedingungen des von mir erteilten SEPA-Mandats bzw. des von mir gewählten Zahlungsdienstes.",
    LaufzeitUndKündigung: "Laufzeit und Kündigung",
    LaufzeitUndKündigung3:
        "Die Fördermitgliedschaft kann ich jederzeit fristlos kündigen. Die Kündigung erfolgt über das Benutzerkonto oder in Textform (z.B. per E-Mail).",
    AnwendbaresRech: "Anwendbares Recht; Verhältnis zu den allgemeinen edusiia Nutzungsbedingungen",
    AnwendbaresRech41: "Es gilt deutsches Recht.",
    AnwendbaresRech42: "Die Nutzungsbedingungen für die edusiia Fördermitgliedschaft gelten zusätzlich zu den allgemeinen edusiia Nutzungsbedingungen.",
    bankTransfer: "Bank Transfer",
    bankTransferParagraph:
        " Du ziehst großen Mehrwert aus edusiia und findest wir sollten unseren Wirkungsrahmen erweitern? Mit einer eduFan Mitgliedschaft wirst du ein wichtiger Teil des besonderen Kreises der edusiia Unterstützer:innen! Du hilfst uns so dabei, unser Netzwerk möglichst vielen Menschen und Organisationen anbieten und es weiterentwickeln zu können. Als Dankeschön bekommst du von uns ein exklusives Grafik-Set, um dein Profil bei edusiia besonders prägnant zu gestalten. ",
    wirDankenDirSEHR: "Wir danken dir SEHR!",
    edusiiaGmbH: "edusiia GmbH",
    IBAN: "IBAN",
    edusiiaIBAN: "DE15 4306 0967 1258 9457 00",
    BIC: "BIC",
    edusiiaGENODEM1GLS: "GENODEM1GLS",
    Bank: "Bank",
    bankName: "GLS Gemeinschaftsbank",
    Vwz: "Vwz.",
    edusiiaVwz: "*Vor- und Zuname*, Förderung",
    postsAtMyRooms: "Posts from my Rooms",
    postiveEmailVerification: "Your email has been verified, you can now signin into your account.",
    confirmEmailMessage: "To complete your registration please check your email to confirm through the link we sent you.",
    confirmEmailMessage2: "If you have created an Organisation Profile please check your organisation email.",
    featureComingSoon: "Feature coming soon",
    VATnote: "*All prices are net prices. A VAT of 19% will be added.",
    payments: "Payments",
    checkOutOrCancelYour: "Check out or cancel your ",
    currentDonationPlan: "current donation plan",
    thankYouForYourSupport: "Thank you for your support",
    subscription: "Subscription",
    roomCreatedSuccessfully: "Room has been created successfully.",
    deleteRoomSuccessfully: "Room has been deleted.",
    editRoomSuccessfully: "Room has been successfully edited.",
    organizationCreatedSuccessfully: "Organization has been created successfully.",
    roomMemberRemoval: "Member has been removed from room.",
    deleteAccountSuccessfully: "Account has been deleted successfully.",
    employeeRemoval: "User has been removed from employees.",
    deleteOrganizationSuccessfully: "Organization has been deleted.",
    newExperienceAddedSuccessfully: "New experience has been added successfully.",
    deleteExperienceSuccessfully: "Work experience has been deleted.",
    editExperienceAddedSuccessfully: "Work experience has been successfully edited.",
    deletePostSuccessfully: "Post has been deleted.",
    leaveRoomSuccessfully: "You have been removed from this room.",
    createAnotherRoom: "To create another room",
    organizationRegisteredAs: "Your organization is registered as a:",
    threeMonthsForFree:
        "All members that sign up for a premium account in 2022 (until Dec 31, 2022) will receive a 3-month free trial and a reduced rate for the following 12 months (25% off).",
    oneMonthForFree: "All members that sign up for a premium account from Jan 1st, 2023 will receive a 1-month free trial.",
    successfullyAddedToRoom: "You have successfully joined this room.",
    errorJoiningFullRoom: "This room is already full. Joining is not possible.",
    pricePage: "Find the price packages",
    joined: "Joined",
    opened: "Opened",
    request: "Request",
    requests: "Requests",
    einzelpreis: "Einzelpreis",
    menge: "Menge",
    MwSt: "MwSt.",
    summeBrutto: "Summe Brutto",
    ContactInfo: "Contact info",
    contactInfoTooltip: "If you wish to display your email address ",
    contactInfoTooltip2: "on your profile so others can contact you,",
    contactInfoTooltip3: "please adjust this in your privacy settings.",
    locationTooltip: "If you do not wish to display your location on your profile, please adjust this in your privacy settings.",
    contactInfoSettings: "Display email adress on my profile.",
    locationSettings: "Display location on my profile",
    confirmEmail: "Confirm your email",
    successEmailConfirm: "Email Confirm!",
    noEventsYet: "No events yet",
    pageNotFound: "Page Not Found",
    pageNotFound2: "Sorry, the page you’re looking for can not be found!",
    dashboard: "Dashboard",
    confirmOrganizationEmail: "To activate the organization you created, firstly verify their email.",
    deletedUser: "Deleted User",
    deletedOrganization: "Deleted Organization",
    thankYouForTheSupport: "We thank you from the bottom of our hearts for your support",
    successfulEdufan:
        "You successfully became an eduFan! If you would like to make any changes to your membership, you can do so in the respective section in your settings.",
    goPremiumSuccessful:
        "You successfully upgraded your profile. If you would like to make any changes to your membership, you can do so in the respective section in your settings.",
    confirm: "Confirm",
    confirmNewEmail: "To continue with new changes you must first verify your new email.",
    event: "Event",
    warning:
        "Please note that these changes become active with the start of the next billing cycle. Note: If you are still in the trial period, the change will take effect immediately and the remaining trial period will expire.",
    addAdmin: "Add admin ",
    added: "Added",
    removeAdmin: "Remove Admin?",
    removeAdminQuestion: "from admins?",

    successfulUpgrade:
        "You have successfully updated your membership to the next billing period. Once the new plan is active, you will see the changes in your profile.",

    postiveNewEmailVerification: "Your new email has been verified, you can now signin into your account with it.",
    organizationEmailVerification: "Your organization email has been verified.",
    newOrganizationEmailVerification: "Your new organization email has been verified and changed.",
    mln: "Mln",
    cantPostInRoom: "Only moderators are able to share content in this room.",
    adminRemoved: "Admin removed",
    fileIsTooBig: "Die Dateigröße deines Bildes ist zu groß. Bitte lade eines mit max. 4 MB hoch",
    creatorsAndManagers: "Creators & Managers",
    roomManagers: "Room Managers",
    uploadWithSpace: "U P L O A D",
    imageWithSpace: "I M A G E",
    moreThanRoomLimit1: "If you want to add more than",
    moreThanRoomLimit2: "rooms please contact us via",
    questionAndAnswers: "Questions & Answers",
    video: "Explanation video",
    help: "Help",
    contact: "Contact",
    watchNow: "Watch now",
    watchLater: "Watch later",
    videoCheckboxText: "Do not show again next time you log in.",
    viedoWelcomeText: "Welcome to edusiia!",
    videoExplanationText: "We have prepared a short video to give you a quick overview of our platform.",
    pinPost: "Pin post",
    unpinPost: "Unpin post",
    postsAndComments: "Posts & Comments",
    post: "Post",
    comment: "Comment",
    seeFullPost: "See full post",
    at: "at",
    seeMore: "See more",
    exploreRooms: "Explore Rooms",
    deletedEvent: "The event was deleted.",
    hashtags: "Hashtags",
    addHashtag: "Add hashtag",
    reachedMaxHashtags: "Maximum number reached",
    hashtagTooltip: "Create your hashtag",
    hashtagTooltip2: "with a hash # in front of the word,",
    hashtagTooltip3: ' press the space bar and click "Save".',
    results: "results",
    result: "result",
    reactions: "Reactions",
    allReactions: "All",
    blurryHashtag: "In order to create your own hashtags, please click here.",
    educationNews: "education news",
    readMore: "Read more",
    Invitations: "Invitations",
    Invitation: "Invitation",
    hashtag: "#hashtag",
    transferOwnership: "Transfer Ownership",
    transferInProgress: "Inhaberschaft wird übertragen",
    transferTooltip1: "You are the owner of this profile and can add/remove admins, ",
    transferTooltip2: "delete the profile, change the email address and manage the membership. ",
    transferTooltip3: " Click here to transfer ownership.",
    transferOwnershipParagraph:
        'To whom do you want to transfer the ownership of this organization profile? Please note that you are relinquishing the additional rights associated with it (add/remove admins, delete profile, change email address, manage membership) and retaining the rights of an admin. More info in the FAQ under "Membership".',
    confirmTransfer: "Please confirm your password to finish the transfer ownership process:",
    successfullyOwnershipTransfer: "Die Inhaberschaft wurde erfolgreich übertragen!",
    onlyOwnerTooltip1: "To transfer ownership of your organizational profile, ",
    onlyOwnerTooltip2: "please first add another member as an admin by ",
    onlyOwnerTooltip3: 'clicking on the plus under the "Admin" heading.',
    NoInvitations: "There are currently no invitation requests",
    seeAllEvents: "All events from premium organisations",
    newPostsInMyRooms: "New posts in my rooms",
    newPostsInOtherRooms: "New posts in other rooms",
    createdByUs: "Created by us...",
    memberIn: "Member in...",
    noJoinedRooms1:
        "Here you usually see the latest posts in the rooms you are a member of. It seems you haven't joined a room yet (or no posts have been shared in the rooms yet). To find rooms that may be of interest to you, use the ",
    roomSearch: "room search ",
    noJoinedRooms2: "or enter a keyword in the general search bar above.",
    noOwnedRooms1:
        "Here you can usually see the latest posts in the rooms you have created. It appears you haven't added a room yet (or no post has been shared in your room). Click here to ",
    noOwnedRooms2: "create a room",
    filterRooms: "Sort",
    newActivity: "New activity",
    alphabetical: "Alphabetical",
    firstJoined: "First joined",
    lastJoined: "Last joined",
    inWhichStateDoYouWork: "In which state/canton do you work?",
    inWhichStateIsTheOrgLocated: "In which state/canton is the organization located?",
    chooseYourState: "Choose your state/canton…",
    typeOfOrgYourInvolvedIn: "What type of organization are you primarily involved in?",
    whatTypeOfOrgDescribesYou: "What type of organization describes you best?",
    chooseMostApplicable: "Choose the most applicable...",
    doYouwantToLeaveRoom: "Are you sure you don't want to be a member of this room anymore?",
    continue: "Continue",
    addLocation: "Add location",
    inactiveRoomNotice_1: "This room is currently inactive. Check out the",
    inactiveRoomNotice_2: " to learn more about possible reasons.",
    error: "Error",
    directChat: "Direct Chat",
    typeChatMessage: "Compose message...",
    chatNoConversations: "There are no chats yet.",
    leaveChat: "Leave conversation",
    doYouwantToLeaveChat: "Are you sure you want to delete this conversation?",
    unseenMessages: "New unseen chat messages",
    rommJoinQuestion: "Verification at join request",
    maybeInteresing: "You might like too",
    shareNow: "Share now",
    editNow: "Edit now",
    discoverNow: "Discover now",
    letsstart: "Let's start!",
    fillNow: "Fill now",
    loadMoreRooms: "Load more rooms"
}
