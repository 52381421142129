import React, { useEffect, useState, useCallback, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { PinIcon, SmallCommentIcon, SmileyFace } from "../../Shared/Components/SvgIcons"
import Image from "../../Shared/Primitive/Image"
import Reaction from "../../Shared/Components/Reactions"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { setPostStatus, setRoomPostId, setRoomPostPhoto } from "./RoomPostSlice"
import RoomPostDropdown from "./RoomPostDropdown"
import { PhotoTypeModal, PostType, RoleType, StorageKey } from "../../../App/enums"
import RoomPostEdit from "./RoomPostEdit"
import useOnScreen, { documentNameLength, formatDate, openLinkInNewTab } from "../../../App/Helpers/Helpers"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { RootState } from "../../../App/store"
import { RoomPosts } from "../../../Types/RoomPosts"
import { convertTagElement } from "../../Shared/Components/Editor/convertTagElement"
import { fileImageType, organizationProfileDefault, profileDefault } from "../../../App/GlobaleVariables"
import { onNavigationToExpandedPost, onUserClick } from "./onNavigation"
import HTTPService from "../../../App/HTTPService"
import { header } from "../../Shared/PhotoDropdown/PhotoDropdownServiceRequests"
import MessageBody from "../../Shared/Components/MessageBody"
import { CallerComponent } from "../../../App/enums"

import useWindowSize from "../../Shared/Hooks/useWindowSize"
import StorageService from "../../../App/StorageService"
import { postSeenRequest } from "./RoomPostRequests"
import { number } from "prop-types"

type RoomPostProps = {
    showCommentIcon: boolean
    postDropdown: boolean
    postDropdownStyle: string
    noCursor?: boolean
    post: RoomPosts
    postType: "posts" | "singularPost"
    showFullPost: boolean
}

function RoomPost({ showCommentIcon, postDropdown, postDropdownStyle, noCursor, post, postType, showFullPost }: RoomPostProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const translation = useSelector(selectTranslations)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const { roomId, id } = useParams()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const [isEmojiSelectorOpen, setIsEmojiSelectorOpen] = useState(false)
    const [isEditable, setIsEditable] = useState(false)
    let emojiRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
    const location = useLocation()
    const isMemberInRoom = useSelector((state: RootState) => state.MemberInRoomStatusSlice.MemberInRoomStatusSlice.isMember)
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const organizationOwnerName = roomDataState.organization?.name
    const organizationOwnerId = roomDataState.organization?.id
    const showRemovePreviewIcon = role === RoleType.USER ? post.content_object?.id === userDataState.id : post.content_object?.id === parseInt(id!)
    const [isCollapsed, setIsCollapsed] = useState(false)
    const ref = useRef() as React.MutableRefObject<HTMLDivElement>
    const isVisible = useOnScreen(ref)
    const [seenRequestSent,SetSeenRequestSent] = useState(false)

    function moderatorOfRoomExists(id: number, first_name: string | undefined) {
        return roomDataState?.room_managers?.some(function (el) {
            return el.id === id && el.first_name === first_name
        })
    }

    const isRoomCreator = organizationOwnerName === post.content_object?.name && organizationOwnerId === post.content_object?.id
    const isRoomModerator = isMemberInRoom && moderatorOfRoomExists(post?.content_object?.id, post?.content_object?.first_name)

    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (isEmojiSelectorOpen && emojiRef.current && !emojiRef.current.contains(event.target as HTMLElement)) {
                setIsEmojiSelectorOpen(false)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    })

    useEffect(() => {
        if(isVisible && !seenRequestSent){
            //mark post as seen
            try{
                switch (role) {
                    case RoleType.USER:
                        postSeenRequest(post.id,'user',userDataState.id)
                        break
                    case RoleType.ORGANIZATION:
                        postSeenRequest(post.id,'organization', Number(id))
                        break
                    default:
                        break
                }
                SetSeenRequestSent(true)
            }catch{
                SetSeenRequestSent(false)
            }
        }
    },[isVisible])

    useEffect(() => {
        showFullPost && setIsCollapsed(!isCollapsed)
    }, [showFullPost])

    useEffect(() => {
        const mentionedUsers = document.querySelectorAll("[data-type]")
        convertTagElement(mentionedUsers, post.tags!, role, id!, userDataState.id)
    }, [id, role, userDataState.id, location.pathname, post.tags])

    const onDeleteModalOpen = () => {
        dispatch(setModalData({ open: true, deleteRoomPost: true }))
        dispatch(setRoomPostId(post.id))
    }

    const onEditPostClick = () => {
        dispatch(setRoomPostId(post.id))
        setIsEditable(true)
    }

    openLinkInNewTab()

    const onEventClick = () => {
        if (post.content_object?.id) {
            navigate(`/organization-profile/${post.content_object?.id!}/event/${post.event_id!}`)
            window.scrollTo(0, 0)
        }
        return
    }

    const onPhotoClick = () => {
        dispatch(setModalData({ open: true, viewPhotoTypeModal: PhotoTypeModal.POST_FILE }))
        dispatch(setRoomPostPhoto(post?.post_file!))
    }

    const pinPostGetRequest = useCallback(() => {
        return HTTPService.get(`/pin-post/${post.id}/`, header)
    }, [post.id])

    const onPinPostClick = () => {
        pinPostGetRequest()
            .then(response => {
                if (response.status === 200) {
                    if (postType === "posts") {
                        dispatch(setPostStatus({ postsStatus: true, singularPostStatus: false, postedInRoomStatus: false }))
                    } else {
                        dispatch(setPostStatus({ postsStatus: true, singularPostStatus: true, postedInRoomStatus: false }))
                    }
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    const onFileClick = (e: (React.MouseEvent<HTMLAnchorElement, MouseEvent>)) => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation();

        const accessToken = StorageService.getItem(StorageKey.accessToken) as string

        fetch(post.post_file!, {
            headers: {
                Authorization: 'Bearer ' + accessToken,
            },
        })
            .then(res => {
                if (res.status == 200) {
                    let blob = res.blob().then(blob => {
                        let objectUrl = window.URL.createObjectURL(blob);
                        let anchor = document.createElement("a");

                        anchor.href = objectUrl;
                        anchor.download = post.post_file.split('media/')[1];
                        anchor.click();

                        window.URL.revokeObjectURL(objectUrl);
                    })
                }
            });
    }

    const isYourPost = () => {
        if (post.content_object) {
            switch (role) {
                case RoleType.USER:
                    if (post.content_type === RoleType.USER.toLowerCase()) return post.content_object!.id! === userDataState.id
                    break
                case RoleType.ORGANIZATION:
                    if (post.content_type === RoleType.ORGANIZATION.toLowerCase()) return post.content_object!.id! === parseInt(id!)
                    break
                default:
                    break
            }
        }
        return false
    }
    const fileName = post.post_file && post?.post_file!.split("/")!.pop()!.split("#")[0].split("?")[0]
    const fileType = fileName && fileName.slice(fileName.indexOf(".") + 1).toUpperCase()
    const screenWidth = useWindowSize()
    const breakpoint = 500

    const openReactionsModal = (
        reaction_info: {
            count: number
            name: string
            users: { id: number; first_name?: string; last_name?: string; profile_photo?: { id: number; url_path: string } }[]
        }[]
    ) => {
        dispatch(setModalData({ open: true, reactionsModal: { status: true, reaction: reaction_info } }))
    }

    const reactionCount = post.reaction_info?.map(reaction => {
        return reaction.count
    })

    const totalReactionCount = reactionCount?.reduce((total, num) => total + num, 0)

    return (
        <div style={{ width: "100%" }} >
            {isEditable ? (
                <RoomPostEdit id={post.id} isEditable={setIsEditable} type={PostType.post} />
            ) : (
                <div className={`room-post ${post.is_pinned && "room-post--is_pinned"}`} id={`${post.id}`} ref={ref}>
                    <div className="room-post__left-panel">
                        {postDropdown && (
                            <div className="room-post__small-date">
                                <RoomPostDropdown
                                    type={post.is_event ? PostType.event : PostType.post}
                                    first_label={translation.editPost}
                                    second_label={translation.deletePost}
                                    third_label={post.is_pinned ? translation.unpinPost : translation.pinPost}
                                    onDeleteModalOpen={onDeleteModalOpen}
                                    onEditPostClick={onEditPostClick}
                                    isYourPost={isYourPost()}
                                    onPinPostClick={onPinPostClick}
                                />
                            </div>
                        )}
                        <div className={`room-post__profile-container `}>
                            <div
                                className={`room-post__profile-photo ${noCursor && "room-post__profile-photo--no-cursor"}`}
                                onClick={() => onUserClick(post.content_type, role, post.content_object?.id, userDataState.id, id!, navigate)}
                            >
                                <Image
                                    src={
                                        post.content_type === RoleType.USER.toLowerCase()
                                            ? post.content_object?.profile_photo?.url_path || profileDefault
                                            : post.content_object?.photo?.url_path || organizationProfileDefault
                                    }
                                    alt="room"
                                    className="image__profile image__profile--size-small image__profile--fit"
                                />
                            </div>
                            <span
                                className={`room-post__username-container ${noCursor && "room-post__username-container-no-cursor"} 
                                ${isRoomCreator && "room-post__username-container--organization"}
                                ${isRoomModerator && "room-post__username-container--admin"} 
                                ${!post.content_object?.id && "room-post__username-container--deleted"}`}
                                onClick={() => onUserClick(post.content_type, role, post.content_object?.id, userDataState.id, id!, navigate)}
                            >
                                {post.content_type === RoleType.USER.toLowerCase()
                                    ? post.content_object === null
                                        ? translation.deletedUser
                                        : post.content_object?.first_name + " " + post.content_object?.last_name
                                    : post.content_object?.name! || translation.deletedOrganization}
                            </span>

                            <div className="room-post__time" onClick={() => onNavigationToExpandedPost(role, post.id, roomId!, id!, navigate)}>
                                {formatDate(post.date, "HH:mm dd.MM.yyyy")!}
                                {post.is_pinned && <PinIcon color="green" modifier="margin-left" />}
                            </div>
                        </div>
                        {post.is_event && (
                            <div className="room-post__event-title" onClick={onEventClick}>
                                {post.event_title}
                            </div>
                        )}
                        <div className={`room-post__post-content ${post.is_event && "room-post__post-content--direction-row"}`}>
                            <div className={`room-post__post-text-container`}>
                                <div className={`room-post__post-text`}>
                                    {
                                        <MessageBody
                                            caller={CallerComponent.post}
                                            id={post.id}
                                            showPreview={post.show_preview}
                                            text={post.content}
                                            showRemovePreviewIcon={showRemovePreviewIcon}
                                            isCollapsed={isCollapsed}
                                            toggleCollapse={() => setIsCollapsed(!isCollapsed)}
                                            onNavigationToExpandedPost={() => onNavigationToExpandedPost(role, post.id, roomId!, id!, navigate)}
                                            showCollapsed={true}
                                            hashtags={post.hashtags}
                                        />
                                    }
                                </div>
                            </div>
                            {post.event_photo && (
                                <div className="room-post__event-image-container" onClick={onEventClick}>
                                    <Image className="room-post__event-image" src={post.event_photo} alt="event" />
                                </div>
                            )}
                            {post.post_file ? (
                                fileImageType.includes(post.file_type!) ? (
                                    <div className="room-post__post-image-container" onClick={onPhotoClick}>
                                        <Image className="room-post__post-image" src={post.post_file} alt="event" />
                                    </div>
                                ) : (
                                    <div className="room-layout__document-container">
                                        <img className="room-layout__document-image" src={`/document-icon.svg`} alt="doc" />
                                        <div className="room-layout__document-content">
                                            <span className="room-layout__document-title" onClick={onFileClick}>
                                                {documentNameLength(fileName, screenWidth, breakpoint)}</span>
                                            <p className="room-layout__document-size">{fileType}</p>
                                        </div>
                                    </div>
                                )
                            ) : null}
                        </div>
                        <div className="room-post__actions-container">
                            <div className="room-post__reactions-container">
                                {post.reaction_info?.map((reaction, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`room-post__reaction ${index === 2 && "room-post__reaction--no-margin"}`}
                                            onClick={() => openReactionsModal(post?.reaction_info!)}
                                        >
                                            <div className="room-post__emoji" title={reaction.name}>
                                                <Image src={`/reactions/${reaction.name}.svg`} className="room-post__emoji-image" />
                                            </div>
                                        </div>
                                    )
                                })}{" "}
                                {totalReactionCount! > 0 && <div className={`room-post__reaction-count `}>{totalReactionCount}</div>}

                                {roomDataState.is_active && (
                                    <>
                                        <div
                                            onClick={() => {
                                                setIsEmojiSelectorOpen(!isEmojiSelectorOpen)
                                            }}
                                            className="room-post__add-emoji-icon"
                                        >
                                            <SmileyFace />
                                        </div>
                                        {isEmojiSelectorOpen && (
                                            <div ref={emojiRef} className="room-post__emoji-picker">
                                                <Reaction
                                                    caller={CallerComponent.post}
                                                    callerId={post.id}
                                                    open={isEmojiSelectorOpen}
                                                    setIsEmojiSelectorOpen={setIsEmojiSelectorOpen}
                                                    postType={postType}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}

                            </div>
                            {showCommentIcon && (
                                <div className="room-post__comments-actions-container room-post__comments-actions-container--display-none">
                                    <div
                                        onClick={() => onNavigationToExpandedPost(role, post.id, roomId!, id!, navigate)}
                                        className="room-post__comments-count"
                                    >
                                        {post.total_commments + " " + translation.comments}
                                    </div>
                                    <div
                                        onClick={() => onNavigationToExpandedPost(role, post.id, roomId!, id!, navigate)}
                                        className="room-post__comment-icon room-post__comment-icon--background-transparent"
                                    >
                                        <SmallCommentIcon />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="room-post__right-panel">
                        {postDropdown && roomDataState.is_active && (
                            <RoomPostDropdown
                                type={post.is_event ? PostType.event : PostType.post}
                                first_label={translation.editPost}
                                second_label={translation.deletePost}
                                third_label={post.is_pinned ? translation.unpinPost : translation.pinPost}
                                onDeleteModalOpen={onDeleteModalOpen}
                                onEditPostClick={onEditPostClick}
                                isYourPost={isYourPost()}
                                onPinPostClick={onPinPostClick}
                            />
                        )}
                        {showCommentIcon && (
                            <div
                                className="room-post__comments-actions-container"
                                onClick={() => onNavigationToExpandedPost(role, post.id, roomId!, id!, navigate)}
                            >
                                <div onClick={() => onNavigationToExpandedPost(role, post.id, roomId!, id!, navigate)} className="room-post__comments-count">
                                    {post.total_commments + " " + translation.comments}
                                </div>
                                <div onClick={() => onNavigationToExpandedPost(role, post.id, roomId!, id!, navigate)} className="room-post__comment-icon">
                                    <SmallCommentIcon />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default RoomPost
