function DropdownItem(props: { name: string, showNotification?: boolean }) {
    return (
        <div>
            <div className="dropdown-element">
                <div className="dropdown-element__text" title={props.name}>
                    {" "}
                    {props.name}
                    {" "}
                </div>
                {props.showNotification && (
                    <div className="notification-bubble"></div>
                )}

            </div>
        </div>
    )
}
export default DropdownItem
