import { number } from "prop-types"
import { headers } from "../../../App/Axios"
import { PostType } from "../../../App/enums"
import HTTPService from "../../../App/HTTPService"
import { RoomPosts } from "../../../Types/RoomPosts"
import { getPhotoSizeErrorMessage } from "../../Shared/SharedSlices/RoomSlice"
import { setPostContentErrorState, setPostStatus, setRoomPostData, setRoomPostsData, setRoomPostsWithPinnedData } from "./RoomPostSlice"

export const getRoomPostsRequest = (roomId: number, pageNumber:number) => {
    return HTTPService.get(`/room/${roomId}/posts/?page=${pageNumber}`, headers)
}

export const getRoomPostRequest = (postId: number) => {
    return HTTPService.get(`/post/${postId}`, headers)
}


const postRequest = (data: FormData,type:PostType.post | PostType.comment) => {
    return HTTPService.post(`/${type}/`, data, headers)
}

export const postSeenRequest = (postId: number,content_type: string,object_id: number) => {
    const data = {'content_type': content_type, 'object_id': object_id}
    return HTTPService.put(`/post/${postId}/seen`,data,headers)
}


export const roomPostPostRequest = (data: FormData,roomPosts:RoomPosts[]) => async (dispatch: Function) => {
    try {
        const response = await postRequest(data,PostType.post)
        if (response.status === 201) {
            if(roomPosts[0]?.is_pinned){            
                dispatch(setRoomPostsWithPinnedData(response.data))
            }else{
                dispatch(setRoomPostsData([response.data, ...roomPosts])) 
            }
            dispatch(setPostContentErrorState(""))
            dispatch(getPhotoSizeErrorMessage(false))
            dispatch(setPostStatus({ postsStatus: false, singularPostStatus: false, postedInRoomStatus: true }))
        }
    } catch (error: any) {
        if (error.response?.status === 413) {
            dispatch(getPhotoSizeErrorMessage(true))
        }
        if(error.response?.status === 400){
            dispatch(setPostContentErrorState(error.response.data.content[0]))
        }
        console.log(error)
    }
}

export const roomCommentPostRequest = (data: FormData,roomPost:RoomPosts) => async (dispatch: Function) => {
    try {
        const response = await postRequest(data, PostType.comment)
        if (response.status === 201) {
            dispatch(setRoomPostData({...roomPost, post_comments:[...roomPost.post_comments, response.data]}))
            dispatch(getPhotoSizeErrorMessage(false))
            dispatch(setPostStatus({ postsStatus: false, singularPostStatus: false, postedInRoomStatus: true }))
            dispatch(setPostContentErrorState(""))
        }
    } catch (error: any) {
        if(error.response?.status === 400){
            dispatch(setPostContentErrorState(error.response.data.content[0]))
        }
        console.log(error)
    }
}


export const editRoomContentPatchRequest = (caller:string, content_id:number,formData:FormData)=>{
   return HTTPService.patch(`/${caller}/${content_id}/edit/`, formData, headers)
}